
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';
@import '../../style/font-family.scss';

.business-card {

 box-shadow: 0 0 3px $box-shadow-color;
 border: 1px solid $bt-white;
 opacity: 0.9;
 @include roboto-light;
 padding: 15px;

 .business-title,
 .business-fa-chevron-right{
  color: $button-primary-color;
  @include roboto-light;
 }

 .business-fa-chevron-right{
  font-size: 36px;
 }

 .business-description {
  color: $primary-text;
 }

 &:hover,
 &:focus {
  box-shadow: 0 0 15px $box-shadow-color;
 }
}
