
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/font-family.scss';
@import '../../style/globalColorVars.scss';

/**
  Breadcrumbs styles start here
*/
.breadcrumbs-container {
    padding-bottom: 30px;
    padding-top: 10px;

    // set the font size for parent element
    p.breadcrumbs-parent {
      font-size: 14px;
      @include roboto-regular;
    }

    .breadcrumbs-parent-title,
    &:hover
    {
      color: $primary-text;
    }
    .faChevronRight-icon {
      padding: 0 10px;
      font-size: 10px;
      vertical-align: middle;
      margin-top: -2px;
    }
    .breadcrumbs-child-title {
      @include roboto-light;
    }
}
