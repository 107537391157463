
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
@import '../../style/globalColorVars.scss';

.search-bar {
 margin-top: 38px;
 display: block;
 position: relative;
 font-size: 16px;
 position: relative;
 display: flex;
 border: 1px solid $box-border-color-light;
 border-radius: 3px;
 height: 42px;
 width: 100%;

 &:hover {
  border-bottom-color: $border-color-light-hover;
 }

 input {
  float: left;
  font-size: 16px;
  width: 100%;
  padding-left: 10px;
  border: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: $bt-white;
  color: $secondary-text;
  height: 40px;

  &:focus {
   background-color: $bt-white;
   outline: 0;
   box-shadow: none;
  }
  &::placeholder {
   color: $secondary-text;
   opacity: 0.5;
  }

 }

 button {
  font-size: inherit;
  height: 40px;
  width: 42px;
  border: 0;
  outline: 0;
  background-color: $background-light-grey;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  cursor: pointer;

  &:hover,
  &:focus {
   background-color: $background-alt;
  }

  svg {
   color: $link-primary;
   width: 16px;
   position: relative;
  }
 }
}// brighttalk-search-form
