
		$cdnUrl: 'undefined';
		$fa-font-path: '/fonts/font-awesome';
	  
.div-gpt-ad-728-try-90-0 {
  width: 728px;
  height: 90px;
  margin: 0 auto;
}
.div-gpt-ad-300-try-50-0 {
  margin: 0 auto;
  width: 300px;
  height: 50px;
}

.div-gpt-ad-300-try-600-0 {
  width: 300px;
  height: 600px;
}
